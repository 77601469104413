import React from 'react';
import { TextField } from '@mui/material';

const SearchField = ({ searchTerm, onSearchChange }) => {
  return (
    <TextField
      label="Buscar"
      variant="outlined"
      fullWidth
      margin="normal"
      value={searchTerm}
      onChange={(e) => onSearchChange(e.target.value)}
       className="MuiTextField-root"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: 'white' },
                              '&:hover fieldset': { borderColor: 'white' },
                              '&.Mui-focused fieldset': { borderColor: 'white' },
                              '&.MuiFormLabel-root': { color: 'white' },
                            },
                            '& .MuiInputLabel-root': { color: 'white' },
                            '& .MuiInputLabel-outlined': { color: 'white' },
                            '& input': { color: 'white' },
                          }}
                          InputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: 'white' } }}
    />
  );
};

export default SearchField;

