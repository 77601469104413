import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMap, Circle, Popup } from 'react-leaflet';
import { Button, Typography } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import './Mapa.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import InfoIcon from '@mui/icons-material/Info';
import CancerDialog from '../CancerDialog/CancerDialog';


// Definindo o ícone padrão
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = DefaultIcon;

// Componente para localizar o usuário
function LocateUser({ setUserLocation }) {
  const map = useMap();

  useEffect(() => {
    map.locate();

    function onLocationFound(e) {
      setUserLocation(e.latlng);
      var radius = e.accuracy / 2;

      L.circle(e.latlng, { radius }).addTo(map);
    }

    function onLocationError() {
      setUserLocation(null); // Define userLocation como null em caso de erro
    }

    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);

    return () => {
      map.off('locationfound', onLocationFound);
      map.off('locationerror', onLocationError);
    };
  }, [map, setUserLocation]);

  return null;
}

function Mapa({ locations, selectedCancer, estudosunicos, onCandidateClick }) {
  const [userLocation, setUserLocation] = useState(null);
  const [open, setOpen] = useState(true); // Modal aberto ao iniciar
  const userMarkerRef = useRef(null);

  useEffect(() => {
    if (userLocation && userMarkerRef.current) {
      userMarkerRef.current.openPopup();
    }
  }, [userLocation]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="map-container">
      <CancerDialog
        open={open}
        onClose={handleClose}
        selectedCancer={selectedCancer}
        estudosunicos={estudosunicos}
        locations={locations}
      />
      <MapContainer center={[-15.7801, -47.9292]} zoom={4} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]} id={`marker-${index}`}>
            <Popup>
              <div className="center-container">
                {location.estadoOriginal !== undefined ? (
                  <Typography variant="h6">{location.cidadeOriginal} - {location.estadoOriginal} </Typography>
                ) : (
                  <Typography variant="h6">{location.cidadeOriginal}</Typography>
                )}
                <Typography>Número de Estudos Clínicos: {location.numeroEstudos}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onCandidateClick(location)}
                >
                  Quero me candidatar!
                </Button>
                <img
                  src={`${process.env.PUBLIC_URL}/imagens/logo.png`}
                  alt="Logo"
                  width="100"
                  height="100"
                />
              </div>
            </Popup>
          </Marker>
        ))}
        {userLocation && (
          <>
            <Marker
              position={userLocation}
              ref={userMarkerRef}
              icon={L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              })}
            >
              <Popup>
                <div>
                  <Typography variant="h6">Sua localização</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onCandidateClick(null)}
                  >
                    Quero me candidatar!
                  </Button>
                </div>
              </Popup>
            </Marker>
            <Circle center={userLocation} radius={50} />
          </>
        )}
        <LocateUser setUserLocation={setUserLocation} />
        <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            <InfoIcon />
          </Button>
        </div>
      </MapContainer>
    </div>
  );
}

export default Mapa;
