import React from 'react';
import { Button, Container, Box, Typography, TextField, Pagination, Modal, Alert, Badge } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Mapa from '../Mapa/mapa';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './PatientView.css';


function PatientView({
    searchTerm,
    selectedCancer,
    locations,
    numeroDeEstudosUnicos,
    customSearch,
    handleCustomSearch,
    handleSearchChange,
    handlePageChange,
    paginatedCancers,
    handleButtonClick,
    filteredCancers,
    currentPage,
    itemsPerPage,
    resetSelection,
    handleCandidateClick,
    handleSubmit,
    phone,
    setPhone,
    phoneError,
    setOpenPhoneModal,
    openPhoneModal,
    isSubmitDisabled,
    showSuccess,
    successMessage,
    topRef,
    userType,
    handleCloseModal,
}) {
  return (
    <div style={{ maxWidth: '700px', alignContent: 'center', margin: 'auto' }}>
      <Container className={`cancer-buttons-container ${selectedCancer ? 'expanded' : ''}`} style={{ background: 'transparent' }}>    
        <Typography variant="h6" component="div">
        <h1 ref={topRef}>Estudos Clínicos Recrutando</h1>
        </Typography>

        <img
          src={`${process.env.PUBLIC_URL}/imagens/logo.png`}
          alt="Logo"
          className={`logo ${selectedCancer ? 'fade-in' : ''}`}
          style={selectedCancer ? { display: 'none' } : {}}
        />
        {!selectedCancer && (
          <TextField
            label="Buscar"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiInputLabel-root': { color: 'white' },
              '& .MuiOutlinedInput-input': { color: 'white' },
              '& .MuiInputLabel-outlined': { color: 'white' },
            }}
            InputProps={{ style: { color: 'white' } }}
            InputLabelProps={{ style: { color: 'white' } }}
          />
        )}
        {!selectedCancer && filteredCancers.length > itemsPerPage && (
          <Pagination
            count={Math.ceil(filteredCancers.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            style={{ marginTop: '16px', color: 'white' }}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': { display: 'none' },
              button: { color: 'white' },
              svg: { color: 'white' },
              'ul > li > div': { color: 'white' },
            }}
          />
        )}
        {!selectedCancer && searchTerm.length >= 0 && filteredCancers.length === 0 && (
          <Box my={2}>
            <Badge badgeContent="busca personalizada" color="secondary">
              <Button variant="contained" fullWidth onClick={handleCustomSearch}>
                {searchTerm}
              </Button>
            </Badge>
          </Box>
        )}
      </Container>
      {selectedCancer && (
        <div >
          <Typography variant="h4" component="div" className="doencapesquisada">
            {selectedCancer}
          </Typography>
          {userType === 'Paciente' && locations.length > 0 && (
            <div className="map-container">
              <img src={`${process.env.PUBLIC_URL}/imagens/logo.png`} alt="Logo" className="logo-inside-map" />
              <Mapa
                locations={locations}
                selectedCancer={selectedCancer}
                onCandidateClick={handleCandidateClick}
                estudosunicos={numeroDeEstudosUnicos}
              />
            </div>
          )}
          <Button
            variant="contained"
            onClick={() => handleCandidateClick(null)}
            fullWidth
            style={{ marginTop: '16px' }}
          >
            {userType === 'Paciente' ? 'Quero me candidatar' : 'Inscrever paciente'}
          </Button>
          {customSearch && (
            <Button
              variant="contained"
              onClick={resetSelection}
              startIcon={<ArrowBackIcon />}
              fullWidth
              style={{ marginTop: '16px', marginBottom: '16px' }}
            >
              Voltar
            </Button>
          )}
        </ div>
      )}
      {paginatedCancers.map((cancer, index) => (
        <Box key={index} my={2} display={selectedCancer && selectedCancer !== cancer ? 'none' : 'block'}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleButtonClick(cancer)}
            startIcon={selectedCancer === cancer ? <ArrowBackIcon /> : null}
          >
            {selectedCancer === cancer ? 'Voltar' : cancer}
          </Button>
        </Box>
      ))}
      <Modal
        open={openPhoneModal}
        onClose={() => setOpenPhoneModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '250px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="div">
            {userType === 'Paciente' ? 'Tenho interesse!' : 'Telefone do paciente ou responsável'}
          </Typography>
          <form onSubmit={handleSubmit}>
            <PhoneInput
              country={'br'}
              value={phone}
              onChange={setPhone}
              masks={{ br: '(..) .....-....' }}
              inputStyle={{ width: '100%', caretColor: 'black' }}
              placeholder={'Digite um telefone celular'}
            />
            {phoneError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {phoneError}
              </Alert>
            )}
            <Button type="submit" variant="contained" fullWidth style={{ marginTop: '16px' }} disabled={isSubmitDisabled}>
              Enviar
            </Button>
            <br /> <br />
            <Button onClick={handleCloseModal}>Fechar</Button>
          </form>
          {showSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default PatientView;
