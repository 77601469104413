import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  Typography, Container, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Pagination, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Alert, useMediaQuery, useTheme, Select, MenuItem, Badge, Box, Modal
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CancerDialog from '../CancerDialog/CancerDialog';
import ReactMarkdown from 'react-markdown';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import MobileTableView from './MobileTableView';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ProfessionalView.css';
import SearchComponent from './SearchComponent';
import SearchField from './SearchField';

function ProfessionalView({ dadosConsolidados, selectedCancer, locations, estudosunicos }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePagination, setActivePagination] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchField, setShowSearchField] = useState(false);

  const [searchTitle, setSearchTitle] = useState('');
  const [searchCondition, setSearchCondition] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [searchPhase, setSearchPhase] = useState('');

  const [openCriteriaModal, setOpenCriteriaModal] = useState(false);
  const [openTimelineModal, setOpenTimelineModal] = useState(false);
  const [criteriaContent, setCriteriaContent] = useState('');
  const [timelineContent, setTimelineContent] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);
  const [openDialog, setOpenDialog] = useState(true);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [filter, setFilter] = useState('');
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dadosAgrupados, setDadosAgrupados] = useState([]);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const tableContainerRef = useRef(null);
  const printRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const agrupados = dadosConsolidados.reduce((acc, dado) => {
      if (!acc[dado.nctId]) {
        acc[dado.nctId] = { ...dado, locations: [{ city: dado.city, state: dado.state, facility: dado.localFacilty }] };
      } else {
        acc[dado.nctId].locations.push({ city: dado.city, state: dado.state, facility: dado.localFacilty });
      }
      return acc;
    }, {});

    setDadosAgrupados(Object.values(agrupados));
  }, [dadosConsolidados]);

  useEffect(() => {
    if (selectedCancer) {
      setOpenDialog(true);
      resetFilters();
    }
  }, [selectedCancer]);

  const resetFilters = () => {
    setSearchTitle('');
    setSearchCondition('');
    setSearchLocation('');
    setSearchPhase('');
    setFilter('');
    setCurrentPage(1);
  };

  const handleSearchToggle = (open) => {
    setShowSearchField(open);
    if (!open) {
      setSearchTerm('');
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - tableContainerRef.current.offsetLeft);
    setStartY(e.pageY - tableContainerRef.current.offsetTop);
    setScrollLeft(tableContainerRef.current.scrollLeft);
    setScrollTop(tableContainerRef.current.scrollTop);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableContainerRef.current.offsetLeft;
    const y = e.pageY - tableContainerRef.current.offsetTop;
    const walkX = x - startX;
    const walkY = y - startY;
    tableContainerRef.current.scrollLeft = scrollLeft - walkX;
    tableContainerRef.current.scrollTop = scrollTop - walkY;
  };

  const handlePageChange = (event, value) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  const handleCriteriaModalOpen = (content) => {
    setCriteriaContent(content);
    setOpenCriteriaModal(true);
  };

  const getAlertMessages = (startDate, completionDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const completion = new Date(completionDate);

    let alerts = [];

    if (completion < today) {
      alerts.push({ message: 'Estudo provavelmente encerrado', severity: 'error' });
    }

    const timeSinceStart = Math.floor((today - start) / (1000 * 60 * 60 * 24));
    if (timeSinceStart <= 90) {
      const days = timeSinceStart;
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      if (months >= 1) {
        alerts.push({ message: `Novo - Adicionado há ${months} ${months > 1 ? 'meses' : 'mês'}`, severity: 'info' });
      } else if (weeks >= 1) {
        alerts.push({ message: `Novo - Adicionado há ${weeks} semana${weeks > 1 ? 's' : ''}`, severity: 'info' });
      } else {
        alerts.push({ message: `Novo - Adicionado há ${days} dia${days > 1 ? 's' : ''}`, severity: 'info' });
      }
    }

    const timeUntilCompletion = Math.floor((completion - today) / (1000 * 60 * 60 * 24));
    if (timeUntilCompletion <= 90 && timeUntilCompletion > 0) {
      const days = timeUntilCompletion;
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      if (months >= 1) {
        alerts.push({ message: `Estudo próximo ao fim do recrutamento - ${months} ${months > 1 ? 'meses restantes' : 'mês restante'}`, severity: 'warning' });
      } else if (weeks >= 1) {
        alerts.push({ message: `Estudo próximo ao fim do recrutamento - ${weeks} semana${weeks > 1 ? 's restantes' : ''}`, severity: 'warning' });
      } else {
        alerts.push({ message: `Estudo próximo ao fim do recrutamento - ${days} dia${days > 1 ? 's restantes' : 'restante'}`, severity: 'warning' });
      }
    }

    return alerts;
  };

  const handleTimelineModalOpen = (content) => {
    const formattedContent = content.map(item => ({
      ...item,
      date: new Date(item.date).toLocaleDateString('pt-BR')
    }));

    const today = new Date();
    const formattedToday = today.toLocaleDateString('pt-BR');

    const updatedContent = [...formattedContent, { label: 'Today', date: formattedToday }];

    updatedContent.sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split('/');
      const [dayB, monthB, yearB] = b.date.split('/');
      return new Date(`${yearA}-${monthA}-${dayA}`) - new Date(`${yearB}-${monthB}-${dayB}`);
    });

    const alerts = getAlertMessages(content[0].date, content[1].date);
    setTimelineContent(updatedContent);
    setAlertMessages(alerts);
    setOpenTimelineModal(true);
  };

  const handleCriteriaModalClose = () => {
    setOpenCriteriaModal(false);
  };

  const handleTimelineModalClose = () => {
    setOpenTimelineModal(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleContactModalOpen = (contact) => {
    setContactInfo(contact || {});
    setOpenContactModal(true);
  };

  const handleContactModalClose = () => {
    setOpenContactModal(false);
  };

  const handleLocationModalOpen = (row) => {
    setSelectedRow(row);
    setOpenLocationModal(true);
  };

  const handleLocationModalClose = () => {
    setOpenLocationModal(false);
  };

  const handlePhoneModalOpen = (row, location = null) => {
    setSelectedLocation(location);
    setSelectedRow(row);
    setOpenPhoneModal(true);
  };

  const handlePhoneModalClose = () => {
    setOpenPhoneModal(false);
    setPhone('');
    setPhoneError('');
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
  
    if (!validatePhone(phone)) {
      setPhoneError('Por favor, digite um telefone válido no formato correto.');
      setIsSubmitDisabled(false);
      return;
    }
  
    setPhoneError('');
  
    const payload = {
      phone,
      selectedCancer,
      dados: selectedRow ? [selectedRow] : [],
      selectedLocation: selectedLocation ? selectedLocation : null,
    };
  
    try {
      await axios.post('https://webhook.joaopauloradiologista.com/webhook/40a8bc45-0c44-4f03-9d8d-b97503b563d8', payload);
      setSuccessMessage('Em breve entraremos em contato por whatsapp');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setOpenPhoneModal(false);
        setIsSubmitDisabled(false);
      }, 3000);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      setIsSubmitDisabled(false);
    }
  };

  const validatePhone = (phone) => {
    const brazilPhoneRegex = /^\+?55\d{2}\d{9}$/;
    const internationalPhoneRegex = /^(?!55)\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (phone.startsWith('+55') || phone.startsWith('55')) {
      return brazilPhoneRegex.test(phone);
    } else {
      return internationalPhoneRegex.test(phone);
    }
  };

  const handleFilterClick = (severity) => {
    setFilter(filter === severity ? '' : severity);
    setCurrentPage(1);
  };

  const handlePaginationChange = (value) => {
    setItemsPerPage(itemsPerPage === value ? 10 : value);
    setActivePagination(itemsPerPage === value ? null : value);
    setCurrentPage(1);
  };

  function removeAccents(str) {
    if (!str) return '';
    const accentsMap = {
      'á': 'a', 'à': 'a', 'ã': 'a', 'â': 'a', 'ä': 'a',
      'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
      'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i',
      'ó': 'o', 'ò': 'o', 'õ': 'o', 'ô': 'o', 'ö': 'o',
      'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u',
      'ç': 'c', 'ñ': 'n',
      'Á': 'A', 'À': 'A', 'Ã': 'A', 'Â': 'A', 'Ä': 'A',
      'É': 'E', 'È': 'E', 'Ê': 'E', 'Ë': 'E',
      'Í': 'I', 'Ì': 'I', 'Î': 'I', 'Ï': 'I',
      'Ó': 'O', 'Ò': 'O', 'Õ': 'O', 'Ô': 'O', 'Ö': 'O',
      'Ú': 'U', 'Ù': 'U', 'Û': 'U', 'Ü': 'U',
      'Ç': 'C', 'Ñ': 'N'
    };

    return str.split('').map(char => accentsMap[char] || char).join('');
  }

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const filteredData = dadosAgrupados.filter(dado => {
    const alerts = getAlertMessages(dado.startDate, dado.completionDate);
    const hasFilter = alerts.some(alert => alert.severity === filter);
    const searchWords = (typeof searchTerm === 'string' ? searchTerm.toLowerCase() : '').split(' ').filter(word => word);

    const matchesSearch = searchWords.every((word) =>
      Object.values(dado).some((field) =>
        typeof field === 'string' && field.toLowerCase().includes(word)
      ) ||
      dado.locations.some(location =>
        Object.values(location).some(locField =>
          typeof locField === 'string' && locField.toLowerCase().includes(word)
        )
      )
    );

    return (!filter || hasFilter) &&
      matchesSearch &&
      (!searchTitle || removeAccents(dado.officialTitle?.toLowerCase()).includes(removeAccents(searchTitle.toLowerCase()))) &&
      (!searchCondition || removeAccents(dado.condition?.toLowerCase()).includes(removeAccents(searchCondition.toLowerCase()))) &&
      (!searchLocation ||
        dado.locations.some(location =>
          removeAccents(location.city?.toLowerCase()).includes(removeAccents(searchLocation.toLowerCase())) ||
          removeAccents(location.facility?.toLowerCase()).includes(removeAccents(searchLocation.toLowerCase())) ||
          removeAccents(location.state?.toLowerCase()).includes(removeAccents(searchLocation.toLowerCase()))
        )
      ) &&
      (!searchPhase || removeAccents(dado.fase?.toLowerCase()).includes(removeAccents(searchPhase.toLowerCase())));
  });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: landscape;
      }
    `,
  });

  const handleExport = () => {
    const cancerName = selectedCancer.toLowerCase();
    const cancerNameFormatted = cancerName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '_');
    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}_${String(date.getMonth() + 1).padStart(2, '0')}_${date.getFullYear()}`;

    const filteredDataWithoutLatLong = filteredData.map(({ lat, lng, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataWithoutLatLong);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dados");
    XLSX.writeFile(wb, `${cancerNameFormatted}_recrutando_brazil_${formattedDate}.xlsx`);
  };

  const renderTimeline = (timelineData) => {
    const today = new Date().toLocaleDateString('pt-BR');

    return (
      <Timeline>
        {timelineData.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <span>{item.label}</span>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={item.date === today ? "primary" : "grey"} />
              {index < timelineData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <span>{(item.date)}</span>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  const renderAlertMessages = (alertMessages) => {
    return alertMessages.length > 0 && (
      <div className="alert-messages">
        {alertMessages.map((alert, index) => (
          <Alert key={index} severity={alert.severity} style={{ marginBottom: '10px' }}>
            {alert.message}
          </Alert>
        ))}
      </div>
    );
  };

  const getStatusBadge = (startDate, completionDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const completion = new Date(completionDate);
    let tooltipText = '';

    if (completion < today) {
      tooltipText = 'Estudo provavelmente encerrado';
      return { badge: 'red', tooltip: tooltipText };
    }

    const timeSinceStart = Math.floor((today - start) / (1000 * 60 * 60 * 24));
    if (timeSinceStart <= 90) {
      if (timeSinceStart < 30) {
        tooltipText = `Novo - Adicionado há ${timeSinceStart} dia${timeSinceStart > 1 ? 's' : ''}`;
      } else if (timeSinceStart < 60) {
        const weeks = Math.floor(timeSinceStart / 7);
        tooltipText = `Novo - Adicionado há ${weeks} semana${weeks > 1 ? 's' : ''}`;
      } else {
        const months = Math.floor(timeSinceStart / 30);
        tooltipText = `Novo - Adicionado há ${months} ${months > 1 ? 'meses' : 'mês'}`;
      }
      return { badge: 'blue', tooltip: tooltipText };
    }

    const timeUntilCompletion = Math.floor((completion - today) / (1000 * 60 * 60 * 24));
    if (timeUntilCompletion <= 90 && timeUntilCompletion > 0) {
      if (timeUntilCompletion < 30) {
        tooltipText = `Estudo próximo ao fim do recrutamento - ${timeUntilCompletion} dia${timeUntilCompletion > 1 ? 'dias restantes' : 'dia restante'} `;
      } else if (timeUntilCompletion < 60) {
        const weeks = Math.floor(timeUntilCompletion / 7);
        tooltipText = `Estudo próximo ao fim do recrutamento - ${weeks} ${weeks > 1 ? 'semanas restantes' : 'semana restante'} `;
      } else {
        const months = Math.floor(timeUntilCompletion / 30);
        tooltipText = `Estudo próximo ao fim do recrutamento - ${months} ${months > 1 ? 'meses restantes' : 'mês restante'}`;
      }
      return { badge: 'orange', tooltip: tooltipText };
    }

    return { badge: 'green', tooltip: 'Estudo com recrutamento ativo' };
  };

  return (
    <Container className="professional-view-container"
      sx={{

        '@media (min-width: 1200px)': {
          maxWidth: '100%',
        },
      }}>
      {selectedCancer && (
        <CancerDialog
          open={openDialog}
          onClose={handleDialogClose}
          selectedCancer={selectedCancer}
          estudosunicos={estudosunicos}
          locations={locations}
        />)}
      {dadosAgrupados.length > 0 && selectedCancer && (
        <>
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
              <Tooltip title="Imprimir">
                <IconButton
                  onClick={handlePrint}
                  color="primary"
                  style={{
                    backgroundColor: '#1976D2',
                    color: 'white'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565C0'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976D2'}
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exportar para Excel">
                <IconButton
                  onClick={handleExport}
                  style={{
                    backgroundColor: 'green',
                    color: 'white'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkgreen'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'green'}
                >
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Estudos Novos">
                <IconButton
                  onClick={() => handleFilterClick('info')}
                  style={{
                    backgroundColor: filter === 'info' ? 'darkblue' : 'blue',
                    color: 'white'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = filter === 'info' ? '#303f9f' : '#3f51b5'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = filter === 'info' ? 'darkblue' : 'blue'}
                  disabled={selectedCancer ? false : true}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Recrutamento terminando">
                <IconButton
                  onClick={() => handleFilterClick('warning')}
                  style={{
                    backgroundColor: filter === 'warning' ? 'darkorange' : 'orange',
                    color: 'white'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = filter === 'warning' ? '#ffa726' : '#ffb74d'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = filter === 'warning' ? 'darkorange' : 'orange'}
                  disabled={selectedCancer ? false : true}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Recrutamento possivelmente encerrado">
                <IconButton
                  onClick={() => handleFilterClick('error')}
                  style={{
                    backgroundColor: filter === 'error' ? 'darkred' : 'red',
                    color: 'white'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = filter === 'error' ? '#e57373' : '#ef5350'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = filter === 'error' ? 'darkred' : 'red'}
                  disabled={selectedCancer ? false : true}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mostrar 50 linhas">
                <IconButton
                  onClick={() => handlePaginationChange(50)}
                  style={{
                    borderColor: 'white',
                    color: activePagination === 50 ? 'white' : 'white',
                    border: '1px solid white',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                    backgroundColor: activePagination === 50 ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = activePagination === 50 ? 'rgba(255, 255, 255, 0.2)' : 'transparent'}
                  disabled={selectedCancer ? false : true}
                >
                  50
                </IconButton>
              </Tooltip>
              <Tooltip title="Mostrar 100 linhas">
                <IconButton
                  onClick={() => handlePaginationChange(100)}
                  style={{
                    borderColor: 'white',
                    color: activePagination === 100 ? 'white' : 'white',
                    border: '1px solid white',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                    backgroundColor: activePagination === 100 ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = activePagination === 100 ? 'rgba(255, 255, 255, 0.2)' : 'transparent'}
                  disabled={selectedCancer ? false : true}
                >
                  100
                </IconButton>
              </Tooltip>
              {isMobile && (
                <SearchComponent onToggle={handleSearchToggle} />
              )}
            </div>
          </div>

          {showSearchField && (
            <SearchField searchTerm={searchTerm} onSearchChange={handleSearch} />
          )}

          <br />
          <br />
          {isMobile ? (
            <MobileTableView
              data={paginatedData}
              onCriteriaModalOpen={handleCriteriaModalOpen}
              onTimelineModalOpen={handleTimelineModalOpen}
              onLocationModalOpen={handleLocationModalOpen}
              onContactModalOpen={handleContactModalOpen}
              handlePhoneModalOpen={handlePhoneModalOpen}
              handlePhoneModalClose={handlePhoneModalClose}
              phone={phone}
              setPhone={setPhone}
              phoneError={phoneError}
              isSubmitDisabled={isSubmitDisabled}
              handlePhoneSubmit={handlePhoneSubmit}
              showSuccess={showSuccess}
              successMessage={successMessage}
            />
          ) : (
            <Paper
              className="table-container"
              ref={tableContainerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              <div ref={printRef}>
                <Table>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell
                        className="header-cell"
                        sx={{
                          fontWeight: '800',
                          fontSize: 'larger',
                          color: '#fff',
                          textAlign: 'left',
                        }}
                      >
                        ID do Estudo
                      </TableCell>
                      <TableCell
                        className="header-cell"
                      >
                        <TextField
                          label="Título Oficial"
                          variant="outlined"
                          size="small"
                          value={searchTitle}
                          onChange={(e) => {
                            setSearchTitle(e.target.value);
                            setCurrentPage(1);
                          }}
                          className="MuiTextField-root"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: 'white' },
                              '&:hover fieldset': { borderColor: 'white' },
                              '&.Mui-focused fieldset': { borderColor: 'white' },
                              '&.MuiFormLabel-root': { color: 'white' },
                            },
                            '& .MuiInputLabel-root': { color: 'white' },
                            '& .MuiInputLabel-outlined': { color: 'white' },
                            '& input': { color: 'white' },
                          }}
                          InputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: 'white' } }}
                        />
                      </TableCell>
                      <TableCell
                        className="header-cell"
                      >
                        <TextField
                          label="Condição"
                          variant="outlined"
                          size="small"
                          value={searchCondition}
                          onChange={(e) => {
                            setSearchCondition(e.target.value);
                            setCurrentPage(1);
                          }}
                          className="MuiTextField-root"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: 'white' },
                              '&:hover fieldset': { borderColor: 'white' },
                              '&.Mui-focused fieldset': { borderColor: 'white' },
                              '&.MuiFormLabel-root': { color: 'white' },
                            },
                            '& .MuiInputLabel-root': { color: 'white' },
                            '& .MuiInputLabel-outlined': { color: 'white' },
                            '& input': { color: 'white' },
                          }}
                          InputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: 'white' } }}
                        />
                      </TableCell>
                      <TableCell
                        className="header-cell"
                      >
                        <TextField
                          label="Localização"
                          variant="outlined"
                          size="small"
                          value={searchLocation}
                          onChange={(e) => {
                            setSearchLocation(e.target.value);
                            setCurrentPage(1);
                          }}
                          className="MuiTextField-root"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: 'white' },
                              '&:hover fieldset': { borderColor: 'white' },
                              '&.Mui-focused fieldset': { borderColor: 'white' },
                              '&.MuiFormLabel-root': { color: 'white' },
                            },
                            '& .MuiInputLabel-root': { color: 'white' },
                            '& .MuiInputLabel-outlined': { color: 'white' },
                            '& input': { color: 'white' },
                          }}
                          InputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: 'white' } }}
                        />
                      </TableCell>
                      <TableCell
                        className="header-cell"
                        sx={{
                          fontWeight: '800 !important',
                          fontSize: 'larger !important',
                          color: '#fff !important',
                          textAlign: 'left !important',
                        }}
                      >
                        Endpoint
                      </TableCell>
                      <TableCell
                        className="header-cell"
                        sx={{
                          fontWeight: '800 !important',
                          fontSize: 'larger !important',
                          color: '#fff !important',
                          textAlign: 'center!important',
                        }}
                      >
                        <Select
                          labelId="filter-phase-label"
                          id="filter-phase"
                          value={searchPhase}
                          onChange={(e) => {
                            setSearchPhase(e.target.value);
                            setCurrentPage(1);
                          }}
                          displayEmpty
                          sx={{
                            color: 'white',
                            '& .MuiSelect-icon': { color: 'white' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                          }}
                        >
                          <MenuItem value="">Todas as Fases</MenuItem>
                          {[...new Set(dadosAgrupados.map((dado) => dado.fase))].map((fase) => (
                            <MenuItem key={fase} value={fase}>{fase}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell
                        className="header-cell"
                        sx={{
                          fontWeight: '800 !important',
                          fontSize: 'larger !important',
                          color: '#fff !important',
                          textAlign: 'center !important',
                        }}
                      >
                        Informações
                      </TableCell>
                      <TableCell
                        className="header-cell"
                        sx={{
                          fontWeight: '800 !important',
                          fontSize: 'larger !important',
                          color: '#fff !important',
                          textAlign: 'center !important',
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((dado, index) => {
                      const { badge, tooltip } = getStatusBadge(dado.startDate, dado.completionDate, dado.overallStatus);

                      return (
                        <TableRow key={index} className="table-row">
                          <TableCell><b>{dado.nctId}</b></TableCell>
                          <TableCell><b>{dado.acronimo}</b><br />{dado.officialTitle}</TableCell>
                          <TableCell>{dado.condition}</TableCell>
                          <TableCell align="center">
                            <Tooltip title=" Todas as Localizações" placement='top'>
                              <IconButton onClick={() => handleLocationModalOpen(dado)}>
                                <Badge badgeContent={dado.locations.length} color="secondary">
                                  <LocationOnIcon />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                            {searchLocation !== '' && (
                              <Typography variant="body2">
                                Encontrado: <b>{searchLocation}</b>
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>{dado.primaryOutcomeMeasure}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                            }}
                          >{dado.fase}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            {dado.locations.length > 0 && (
                              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                                <Tooltip title="Vamos te ajudar a entrar em contato!" placement='top'>
                                  <IconButton onClick={() => handlePhoneModalOpen(dado)}>
                                    <PhoneIcon sx={{ 
                                      color: 'green', 
                                      borderRadius: '50%', 
                                      padding: '4px', 
                                      border: '1px solid green'
                                    }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                              <Tooltip title="Critérios de Inclusão e Exclusão" placement='top'>
                                <IconButton onClick={() => handleCriteriaModalOpen(dado.eligibilityCriteria)}>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Datas Importantes" placement='top'>
                                <IconButton onClick={() => handleTimelineModalOpen([{ label: 'Start Date', date: dado.startDate }, { label: 'Completion Date', date: dado.completionDate }, { label: 'Status Verified Date', date: dado.statusVerifiedDate }])}>
                                  <CalendarTodayIcon />
                                </IconButton>
                              </Tooltip>
                              {(dado.nomeContato || dado.email || dado.telefone || dado.leadSponsorName) && (
                                <Tooltip title="Contato" placement='top'>
                                  <IconButton onClick={() => handleContactModalOpen(dado)}>
                                    <ContactMailIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title={tooltip || ''} placement='top'>
                              <span className={`status-badge ${badge}`}>
                                {dado.overallStatus}
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Paper>
          )}
          <Pagination
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                color: 'white',
                marginBottom: '50px',
                minWidth: '28px',
              },
            }}
          />
        </>
      )}

      <Dialog open={openCriteriaModal} onClose={handleCriteriaModalClose}>
        <DialogTitle align='center' sx={{ color: 'white', backgroundColor: '#1976D2' }}>Critérios de Inclusão e Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <ReactMarkdown>{criteriaContent}</ReactMarkdown>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaModalClose} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTimelineModal} onClose={handleTimelineModalClose}>
        <DialogTitle align='center' sx={{ color: 'white', backgroundColor: '#1976D2' }}>Datas Importantes</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            {alertMessages.length > 0 && (
              <>
                <br />
                {renderAlertMessages(alertMessages)}
              </>
            )}
            {renderTimeline(timelineContent)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTimelineModalClose} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openContactModal} onClose={handleContactModalClose}>
        <DialogTitle align='center' sx={{ color: 'white', backgroundColor: '#1976D2' }}>
          Informações de Contato
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <br />
            {contactInfo.nomeContato && (
              <div>
                <strong>Nome do Contato:</strong> {contactInfo.nomeContato}
              </div>
            )}
            {contactInfo.email && (
              <div>
                <strong>Email:</strong> {contactInfo.email}
              </div>
            )}
            {contactInfo.telefone && (
              <div>
                <strong>Telefone:</strong> {contactInfo.telefone}
              </div>
            )}
            {contactInfo.leadSponsorName && (
              <div>
                <strong>Lead Sponsor Name:</strong> {contactInfo.leadSponsorName}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactModalClose} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openLocationModal} onClose={handleLocationModalClose}>
        <DialogTitle align='center' sx={{ color: 'white', backgroundColor: '#1976D2' }}>
          Centros Recrutadores
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <br />
            {selectedRow && selectedRow.locations.map((location, index) => (
              <div key={index} style={{ marginBottom: '16px', padding: '8px', backgroundColor: '#f9f9f9', borderRadius: '4px', textAlign: 'center' }}>
                <div><strong>{location.facility}</strong></div>
                <div><strong>Cidade:</strong> {location.city} {location.state && `- ${location.state}`}</div>
                <Tooltip title="Telefone" placement='top'>
                  <span>
                    <IconButton sx={{ mt: 1, mb: 1 }} onClick={() => handlePhoneModalOpen(selectedRow, location)}>
                      <PhoneIcon sx={{ 
                        color: 'green', 
                        borderRadius: '50%', 
                        padding: '4px', 
                        border: '1px solid green'
                      }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLocationModalClose} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openPhoneModal}
        onClose={handlePhoneModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '250px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="div">Te ajudaremos com o contato com o Centro Recrutador!</Typography>
          <form onSubmit={handlePhoneSubmit}>
            <PhoneInput
              country={'br'}
              value={phone}
              onChange={setPhone}
              masks={{ br: '(..) .....-....' }}
              inputStyle={{ width: '100%', caretColor: 'black' }}
              placeholder={'Digite um telefone celular'}
            />
            <Button type="submit" variant="contained" fullWidth style={{ marginTop: '16px' }} disabled={isSubmitDisabled}>Enviar</Button>
            {phoneError && (
              <Alert severity="error" sx={{ mt: 2 }}>{phoneError}</Alert>
            )}
            {showSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {successMessage}
              </Alert>
            )}
            <br /><br />
            <DialogActions>
              <Button onClick={handlePhoneModalClose} color="primary">Fechar</Button>
            </DialogActions>
          </form>
        </Box>
      </Modal>
    </Container>
  );
}

export default ProfessionalView;
