import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const SearchComponent = ({ searchTerm, onSearchChange, onToggle }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    onToggle(!open);
  };

  return (
    <>
      <Tooltip title="Buscar">
        <IconButton
          onClick={handleToggle}
          style={{
            backgroundColor: open ? '#1565C0' : '#1976D2',
            color: 'white',
            width: '40px',
            height: '40px',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = open ? '#135BA1' : '#1565C0'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = open ? '#1565C0' : '#1976D2'}
        >
          <SearchIcon style={{ fontSize: '30px' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SearchComponent;
