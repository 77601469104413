import React from 'react';
import { Table, TableBody, TableCell, TableRow, Paper, IconButton, Tooltip, Badge } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

const StyledTableRow = styled(TableRow)`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.$bgColor};
  border-bottom: none;
`;

const StyledTableCell = styled(TableCell)`
  flex: 2;
  border-bottom: none;
`;

const IconCell = styled(TableCell)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  gap: 8px;
  border-bottom: none;
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const StatusBadge = styled.span`
  display: block;
  text-align: center;
  margin-bottom: 8px;
`;

const MobileTableView = ({
  data,
  onCriteriaModalOpen,
  onTimelineModalOpen,
  onLocationModalOpen,
  onContactModalOpen,
  handlePhoneModalOpen,
}) => {

  const getStatusBadge = (startDate, completionDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const completion = new Date(completionDate);
    let tooltipText = '';

    if (completion < today) {
      tooltipText = 'Estudo provavelmente encerrado';
      return { badge: 'red', tooltip: tooltipText };
    }

    const timeSinceStart = Math.floor((today - start) / (1000 * 60 * 60 * 24));
    if (timeSinceStart <= 90) {
      if (timeSinceStart < 30) {
        tooltipText = `Novo - Adicionado há ${timeSinceStart} dia${timeSinceStart > 1 ? 's' : ''}`;
      } else if (timeSinceStart < 60) {
        const weeks = Math.floor(timeSinceStart / 7);
        tooltipText = `Novo - Adicionado há ${weeks} semana${weeks > 1 ? 's' : ''}`;
      } else {
        const months = Math.floor(timeSinceStart / 30);
        tooltipText = `Novo - Adicionado há ${months} ${months > 1 ? 'meses' : 'mês'}`;
      }
      return { badge: 'blue', tooltip: tooltipText };
    }

    const timeUntilCompletion = Math.floor((completion - today) / (1000 * 60 * 60 * 24));
    if (timeUntilCompletion <= 90 && timeUntilCompletion > 0) {
      if (timeUntilCompletion < 30) {
        tooltipText = `Estudo próximo ao fim do recrutamento - ${timeUntilCompletion} dia${timeUntilCompletion > 1 ? 'dias restantes' : 'dia restante'} `;
      } else if (timeUntilCompletion < 60) {
        const weeks = Math.floor(timeUntilCompletion / 7);
        tooltipText = `Estudo próximo ao fim do recrutamento - ${weeks} ${weeks > 1 ? 'semanas restantes' : 'semana restante'} `;
      } else {
        const months = Math.floor(timeUntilCompletion / 30);
        tooltipText = `Estudo próximo ao fim do recrutamento - ${months} ${months > 1 ? 'meses restantes' : 'mês restante'}`;
      }
      return { badge: 'orange', tooltip: tooltipText };
    }

    return { badge: 'green', tooltip: 'Estudo com recrutamento ativo' };
  };

  // Define the color mapping
  const colors = ['white', '#e9e9e9'];

  // Create a mapping of study IDs to colors
  const studyColorMap = {};
  let colorIndex = 0;

  data.forEach(dado => {
    if (!studyColorMap[dado.nctId]) {
      studyColorMap[dado.nctId] = colors[colorIndex % colors.length];
      colorIndex += 1;
    }
  });

  return (
    <Paper>
      <Table style={{ width: '100%', tableLayout: 'fixed' }}>
        <TableBody>
          {data.map((dado, index) => {
            const { badge, tooltip } = getStatusBadge(dado.startDate, dado.completionDate);
            const bgColor = studyColorMap[dado.nctId]; // Get the color for the current study
            return (
              <StyledTableRow key={index} $bgColor={bgColor}>
                <StyledTableCell>
                  <b>ID:</b> {dado.nctId}<br />
                  <b>Título:</b> {dado.officialTitle}<br />
                  {dado.acronimo && (
                    <>
                      <b>Acrônimo:</b> {dado.acronimo}<br />
                    </>
                  )}
                  <b>Endpoint:</b> {dado.primaryOutcomeMeasure}<br />
                  <b>Fase:</b> {dado.fase}<br />
                </StyledTableCell>
                <IconCell>
                  <Tooltip title={tooltip}>
                    <StatusBadge className={`status-badge ${badge}`}>
                      {dado.overallStatus}
                    </StatusBadge>
                  </Tooltip>
                  <IconRow>
                    <Tooltip title="Localizações">
                      <IconButton onClick={() => onLocationModalOpen(dado)}>
                        <Badge badgeContent={dado.locations.length} color="secondary">
                          <LocationOnIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Critérios de Inclusão e Exclusão">
                      <IconButton onClick={() => onCriteriaModalOpen(dado.eligibilityCriteria)}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Datas Importantes">
                      <IconButton onClick={() => onTimelineModalOpen([{ label: 'Start Date', date: dado.startDate }, { label: 'Completion Date', date: dado.completionDate }, { label: 'Status Verified Date', date: dado.statusVerifiedDate }])}>
                        <CalendarTodayIcon />
                      </IconButton>
                    </Tooltip>
                    {dado.nomeContato && (
                      <Tooltip title="Contato">
                        <IconButton onClick={() => onContactModalOpen(dado)}>
                          <ContactMailIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Vamos te ajudar a entrar em contato!" placement='top'>
                      <IconButton onClick={() => handlePhoneModalOpen(dado)}>
                        <PhoneIcon sx={{ 
                          color: 'green', 
                          borderRadius: '50%', 
                          padding: '4px', 
                          border: '1px solid green'
                        }} />
                      </IconButton>
                    </Tooltip>
                  </IconRow>
                </IconCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default MobileTableView;
