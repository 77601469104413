// src/App.js

import React from 'react';
import './App.css';
import BotoesCancer from './Components/BotoesCancer/BotoesCancer';
import Rodape from './Components/rodape/rodape';



function App() {
  return (
    <>
    <div className="App">
      <BotoesCancer /> {/* Botões de câncer e mapa */}
    </div>
    <Rodape /> {/* Rodapé */}

    </ >

  );
}

export default App;
