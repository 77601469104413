import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';


function CancerDialog({ open, onClose, selectedCancer, estudosunicos, locations }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle align='center' variant='h4'>
        {selectedCancer}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" textAlign={'center'}>Total de Estudos: {estudosunicos}</Typography>
        <Typography variant="h5" textAlign={'center'}>Número de Cidades: {locations.length}</Typography>
        <br />
        <div style={{ textAlign: 'center' }}>
          <img 
            src={`${process.env.PUBLIC_URL}/imagens/logo.png`}
            alt="Logo" 
            width="200" 
            style={{ height: 'auto' }} 
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancerDialog;

