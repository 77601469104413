import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Button, Container, Box, Modal, Alert } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { TiposdeCancer, traduzirDoenca } from './TiposdeCancer';
import PatientView from '../PatientView/PatientView';
import UserTypeToggle from '../UserTypeToggle/UserTypeToggle'; 
import ProfessionalView from '../ProfessionalView/ProfessionalView';
import './BotoesCancer.css';


function BotoesCancer() {
  const [selectedCancer, setSelectedCancer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [locations, setLocations] = useState([]);
  const [customSearch, setCustomSearch] = useState(false);
  const [numeroDeEstudosUnicos, setNumeroDeEstudosUnicos] = useState(0);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [userType, setUserType] = useState(null);
  const [dadosConsolidados, setDadosConsolidados] = useState([]);

  const itemsPerPage = 4;
  const topRef = useRef(null);

  const cancers = TiposdeCancer();

  const normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  const handleButtonClick = async (cancer) => {
    if (selectedCancer === cancer) {
      resetSelection();
    } else {
      setSelectedCancer(cancer);
      setCustomSearch(false);
      try {
        const condition = traduzirDoenca(cancer);
        await fetchTrials(condition);
      } catch (error) {
        console.error('Error fetching trials:', error);
      }
    }
  };

  const fetchTrials = async (condition) => {
    const url = `https://clinicaltrials.gov/api/v2/studies?format=json&query.cond=${condition}&query.locn=Brazil&filter.overallStatus=RECRUITING&postFilter.overallStatus=RECRUITING&fields=NCTId|OfficialTitle|Acronym|OverallStatus|StatusVerifiedDate|Condition|CompletionDate|LeadSponsorName|CollaboratorName|LocationCountry|Phase|PrimaryOutcomeMeasure|StartDate|EligibilityCriteria|LocationFacility|LocationCity|LocationState|CentralContactName|CentralContactPhone|CentralContactEMail|LocationGeoPoint&countTotal=true&pageSize=1000`;
    try {
      const response = await axios.get(url);
      const trials = response.data.studies;
      if (!trials || trials.length === 0) {
        setErrorMessage(`Não foram encontrados estudos clínicos com a palavra-chave ${searchTerm} recrutando no Brasil`);
        resetSelection();
        return;
      }

      let dadosConsolidadosTemp = [];

      trials.forEach(trial => {
        let nctId = trial.protocolSection.identificationModule.nctId;
        let officialTitle = trial.protocolSection.identificationModule.officialTitle;
        let Acronym = trial.protocolSection.identificationModule.acronym;
        let condition = trial.protocolSection.conditionsModule.conditions.map(c => c).join(', ');

        let nomeContato = 'não informado';
        let email = 'não informado';
        let telefone = 'não informado';

        if (
          trial &&
          trial.protocolSection &&
          trial.protocolSection.contactsLocationsModule &&
          Array.isArray(trial.protocolSection.contactsLocationsModule.centralContacts)
        ) {
          nomeContato = trial.protocolSection.contactsLocationsModule.centralContacts
            .map(c => c.name)
            .join(', ');
          email = trial.protocolSection.contactsLocationsModule.centralContacts.map(c => c.email).join(', ');
          telefone = trial.protocolSection.contactsLocationsModule.centralContacts.map(c => c.phone).join(', ');
        }

        let fase = trial.protocolSection.designModule.phases ? trial.protocolSection.designModule.phases.map(p => p).join(', ') : 'Não informada';
        let statusVerifiedDate = trial.protocolSection.statusModule.statusVerifiedDate;
        let overallStatus = trial.protocolSection.statusModule.overallStatus;
        let startDate = trial.protocolSection.statusModule.startDateStruct.date;
        let completionDate = trial.protocolSection.statusModule.completionDateStruct.date;
        let leadSponsorName = trial.protocolSection.sponsorCollaboratorsModule.leadSponsor.name;
        let primaryOutcomeMeasure = 'não informada';

        if (
          trial &&
          trial.protocolSection &&
          trial.protocolSection.outcomesModule &&
          Array.isArray(trial.protocolSection.outcomesModule.primaryOutcomes)
        ) {
          primaryOutcomeMeasure = trial.protocolSection.outcomesModule.primaryOutcomes
            .map(o => o.measure)
            .join(', ');
        }

        let eligibilityCriteria = trial.protocolSection.eligibilityModule.eligibilityCriteria;

        if (trial.protocolSection.contactsLocationsModule && trial.protocolSection.contactsLocationsModule.locations) {
          trial.protocolSection.contactsLocationsModule.locations.forEach(location => {
            if ((location.country === 'Brazil' || location.country === 'Brasil') && location.geoPoint) {
                dadosConsolidadosTemp.push({
                nctId: nctId,
                officialTitle: officialTitle,
                acronimo: Acronym,
                condition: condition,
                nomeContato: nomeContato,
                email: email,
                telefone: telefone,
                fase: fase,
                statusVerifiedDate: statusVerifiedDate,
                overallStatus: overallStatus,
                startDate: startDate,
                completionDate: completionDate,
                leadSponsorName: leadSponsorName,
                primaryOutcomeMeasure: primaryOutcomeMeasure,
                eligibilityCriteria: eligibilityCriteria,
                localFacilty: location.facility,
                city: location.city,
                state: location.state, // Adicionei esta linha para incluir o estado
                country: location.country,
                lat: location.geoPoint.lat,
                lng: location.geoPoint.lon
              });
            }
          });
        }
      });

      setDadosConsolidados(dadosConsolidadosTemp);

      dadosConsolidadosTemp = dadosConsolidadosTemp.map(local => {
        let cidade = local.city;
        if (cidade === 'Salvador de Bahia') {
          cidade = 'Salvador';
        }
        if (cidade === 'San Paolo') {
          cidade = 'Sao Paulo';
        }
        return { ...local, city: cidade };
      });

      function normalizeCityName(city) {
        return city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      }

      let cidades = [];
      let estudosMap = {};

      const nctIdsUnicos = new Set();

      dadosConsolidadosTemp.forEach(estudo => {
        nctIdsUnicos.add(estudo.nctId);
      });

      const numeroDeEstudosUnicos = nctIdsUnicos.size;
      setNumeroDeEstudosUnicos(numeroDeEstudosUnicos);

      dadosConsolidadosTemp.forEach(local => {
        let cidadeOriginal = local.city;
        let estadoOriginal = local.state; // Adicionei esta linha para capturar o estado original
        let cidade = normalizeCityName(cidadeOriginal);
        let lat = local.lat;
        let lng = local.lng;
        let nctId = local.nctId;
        let key = `${cidade}-${nctId}`;

        let index = cidades.findIndex(c => c.cidade === cidade);

        if (index === -1) {
          cidades.push({ cidade, cidadeOriginal, estadoOriginal, lat, lng, numeroEstudos: 1 });
          estudosMap[key] = true;
        } else {
          if (!estudosMap[key]) {
            cidades[index].numeroEstudos++;
            estudosMap[key] = true;
          }
        }
      });

      cidades = cidades.map(cidade => {
        let palavras = cidade.cidade.split(' ');
        let nomeFormatado = palavras.map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1)).join(' ');
        return { ...cidade, cidade: nomeFormatado };
      });

      setLocations(cidades);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
    setSelectedCancer(null);
    setLocations([]);
  };

  const handleClickOutside = useCallback((event) => {
    if (userType !== 'Profissional') {
      if (
        !event.target.closest('.cancer-buttons-container') &&
        !event.target.closest('.table-container') &&
        !event.target.closest('.map-container') &&
        !event.target.closest('.phone-modal') &&
        !['INPUT', 'BUTTON'].includes(event.target.tagName)
      ) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [userType]);

  const resetSelection = () => {
    setSelectedCancer(null);
    setSearchTerm('');
    setLocations([]);
    setCustomSearch(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const filteredCancers = cancers.filter((cancer) => normalizeString(cancer).includes(normalizeString(searchTerm)));

  const paginatedCancers = filteredCancers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCustomSearch = async () => {
    setSelectedCancer(searchTerm);
    setCustomSearch(true);
    const condition = traduzirDoenca(searchTerm);
    const response = await fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=pt&tl=en&dt=t&q=${condition}`);
    const data = await response.json();
    const traducao = data[0][0][0];
    fetchTrials(traducao);
    setSearchTerm('');
  };

  const handleCandidateClick = (location) => {
    setSelectedLocation(location);
    setOpenPhoneModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitDisabled(true);

    if (!validatePhone(phone)) {
      setPhoneError('Por favor, digite um telefone válido no formato correto.');
      setIsSubmitDisabled(false);
      return;
    }

    setPhoneError('');

    let payload;

    if (selectedLocation && selectedLocation['cidade']) {
        payload = {
          phone,
          cancerType: selectedCancer,
          cidade: selectedLocation['cidade'],
        }; 
    } else {
        payload = {
          phone,
          cancerType: selectedCancer,
        };
    }
    
    


    try {
      await axios.post('https://webhook.joaopauloradiologista.com/webhook/40a8bc45-0c44-4f03-9d8d-b97503b563d8', payload);
      setSuccessMessage('Em breve entraremos em contato por whatsapp');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setOpenPhoneModal(false);
        setIsSubmitDisabled(false);
      }, 3000);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      setIsSubmitDisabled(false);
    }
  };

  const validatePhone = (phone) => {
    const brazilPhoneRegex = /^\+?55\d{2}\d{9}$/;
    const internationalPhoneRegex = /^(?!55)\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (phone.startsWith('+55') || phone.startsWith('55')) {
      return brazilPhoneRegex.test(phone);
    } else {
      return internationalPhoneRegex.test(phone);
    }
  };

  const handleUserTypeClick = (type) => {
    setUserType(type);
  };

  const handleModalClose = () => {
    setErrorMessage('');
    resetSelection();
  };

  const handleCloseModal = () => {
    setOpenPhoneModal(false);
    setPhone('');
    setIsSubmitDisabled(false);
  };

  return (
    <div style={{ width: '100%'}}>
      {!userType ? (
        <div >
        <Container className={`cancer-buttons-container ${selectedCancer ? 'expanded' : ''}`}>
          <h1>Escolha seu perfil</h1>
          <Box my={2}>
            <Button variant="contained" onClick={() => handleUserTypeClick('Paciente')}>
              Paciente
            </Button>
          </Box>
          <Box my={2}>
            <Button variant="contained"  onClick={() => handleUserTypeClick('Profissional')}>
             Profissional da área da Saúde
            </Button>
          </Box>
          <img
            src={`${process.env.PUBLIC_URL}/imagens/logo.png`}
            alt="Logo"
            className={`logo ${selectedCancer ? 'fade-in' : ''}`}
            style={selectedCancer ? { display: 'none' } : {}}
          />
      </Container>
      </div >
      ) : (
        <> <UserTypeToggle userType={userType} handleUserTypeClick={handleUserTypeClick} />
          <PatientView
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedCancer={selectedCancer}
            setSelectedCancer={setSelectedCancer}
            locations={locations}
            numeroDeEstudosUnicos={numeroDeEstudosUnicos}
            fetchTrials={fetchTrials}
            customSearch={customSearch}
            handleCustomSearch={handleCustomSearch}
            handleSearchChange={handleSearchChange}
            handlePageChange={handlePageChange}
            paginatedCancers={paginatedCancers}
            handleButtonClick={handleButtonClick}
            filteredCancers={filteredCancers}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            resetSelection={resetSelection}
            handleCandidateClick={handleCandidateClick}
            handleSubmit={handleSubmit}
            phone={phone}
            setPhone={setPhone}
            phoneError={phoneError}
            setOpenPhoneModal={setOpenPhoneModal}
            openPhoneModal={openPhoneModal}
            isSubmitDisabled={isSubmitDisabled}
            showSuccess={showSuccess}
            successMessage={successMessage}
            topRef={topRef}
            userType={userType}
            handleCloseModal={handleCloseModal}
          />
          {userType === 'Profissional' && (
            <>
              <ProfessionalView dadosConsolidados={dadosConsolidados} selectedCancer={selectedCancer} locations={locations} estudosunicos={numeroDeEstudosUnicos}  />
            </>
          )}
        </>
      )}

      <Modal
        open={!!errorMessage}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 280,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Alert severity="error">{errorMessage}</Alert>
          <br />
          <Button onClick={handleModalClose}>Fechar</Button>
        </Box>
      </Modal>
    </ div>
  );
}

export default BotoesCancer;
