import React, { useState, useEffect } from 'react';

const Rodape = () => {
  const [apiVersion, setApiVersion] = useState('');
  const [dataTimestamp, setDataTimestamp] = useState('');

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    fetch('https://clinicaltrials.gov/api/v2/version')
      .then(response => response.json())
      .then(data => {
        setApiVersion(data.apiVersion);
        setDataTimestamp(formatTimestamp(data.dataTimestamp));
      })
      .catch(error => console.error('Error fetching API data:', error));
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', color: 'white', fontSize: '0.575rem', backgroundColor: '#004aad' }}>
      Fonte: ClinicalTrials.gov | Versão: {apiVersion} | Atualizado em: {dataTimestamp} hrs
    </div>
  );
};

export default Rodape;

