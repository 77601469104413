function TiposdeCancer() {
    const cancers = [
        'Câncer',
        'Câncer de Pulmão', 'Câncer de Mama', 'Câncer de Cólon', 'Câncer de Próstata',
        'Câncer de Pele', 'Câncer de Fígado', 'Câncer de Pâncreas', 'Câncer de Estômago',
        'Câncer de Rim', 'Câncer de Bexiga', 'Câncer de Ovário', 'Câncer de Endométrio',
        'Câncer de Esôfago', 'Câncer de Cabeça e Pescoço', 'Câncer de Tireoide', 'Câncer de Testículo',
        'Câncer de Cérebro', 'Linfoma de Hodgkin', 'Linfoma Não-Hodgkin', 'Leucemia',
        'Mieloma Múltiplo', 'Câncer de Vias Biliares', 'Câncer de Glândula Salivar', 'Câncer de Vulva',
        'Câncer de Vagina', 'Câncer de Pleura', 'Câncer de Peritônio', 'Câncer de Nasofaringe',
        'Câncer de Hipofaringe', 'Câncer de Laringe', 'Câncer de Pequenas Células do Pulmão',
        'Câncer de Células Renais', 'Sarcoma de Tecidos Moles', 'Osteossarcoma', 'Sarcoma de Ewing',
        'Mesotelioma', 'Câncer de Adrenal', 'Câncer de Medula Espinhal', 'Câncer de Útero',
        'Câncer de Colo do Útero', 'Câncer Peniano', 'Câncer de Paratireoide',
        'Câncer de Vesícula Biliar', 'Câncer de Glândula Pituitária', 'Plasmocitoma',
        'Câncer de Nervo Periférico', 'Melanoma', 'Câncer de Pele Não-Melanoma',
        'Câncer de Apêndice', 'Tumor de Saco Vitelino', 'Câncer de Cordão Espinhal',
        'Câncer de Intestino Delgado', 'Câncer de Ampola de Vater', 'Câncer de Cavidade Oral',
        'Câncer de Seio Paranasal', 'Câncer de Glândula Lacrimal',
        'Adenocarcinoma de Pulmão', 'Carcinoma de Células Escamosas de Pulmão', 'Carcinoma de Células Pequenas de Pulmão',
        'Glioblastoma Multiforme', 'Astrocitoma', 'Oligodendroglioma', 'Ependimoma',
        'Carcinóide Pulmonar', 'Carcinóide Gastrointestinal', 'Tumor Estromal Gastrointestinal',
        'Linfoma de Burkitt', 'Linfoma Folicular', 'Leucemia Linfoblástica Aguda', 'Leucemia Mieloide Aguda',
        'Leucemia Mieloide Crônica', 'Leucemia Linfocítica Crônica',
        'Carcinoma Ductal Invasivo', 'Carcinoma Lobular Invasivo',
        'Carcinoma Hepatocelular', 'Colangiocarcinoma', 'Tumor de Células de Leydig',
        'Tumor de Células de Sertoli', 'Tumor de Células Germinativas Não-Seminomatoso',
        'Rabdomiossarcoma', 'Sarcoma de Kaposi', 'Lipossarcoma', 'Leiomiossarcoma',
        'Tumor de Wilms', 'Retinoblastoma', 'Neuroblastoma', 'Meduloblastoma',
        'Carcinoma Adrenocortical', 'Carcinoma Papilífero da Tireoide', 'Carcinoma Folicular da Tireoide',
        'Carcinoma Medular da Tireoide', 'Carcinoma Anaplásico da Tireoide',
        'Câncer de Células de Merkel', 'Carcinoma Basocelular', 'Carcinoma Espinocelular',
        'Cistadenoma Seroso do Ovário', 'Cistadenocarcinoma Mucinoso do Ovário',
        'Tumor de Células da Granulosa do Ovário', 'Tumor de Brenner do Ovário', 'Demência', 'Câncer', 'Hiperplasia Prostática Benigna',
        'Hipertensão Arterial', 'Diabetes Mellitus', 'Doença de Alzheimer'
      
      ];
    return cancers;
}

const traducoesDeCancer = {
    'Câncer': 'Cancer','Câncer de Pulmão': 'Lung Cancer', 'Câncer de Mama': 'Breast Cancer', 'Câncer de Cólon': 'Colon Cancer',
    'Câncer de Próstata': 'Prostate Cancer', 'Câncer de Pele': 'Skin Cancer', 'Câncer de Fígado': 'Liver Cancer',
    'Câncer de Pâncreas': 'Pancreatic Cancer', 'Câncer de Estômago': 'Stomach Cancer', 'Câncer de Rim': 'Kidney Cancer',
    'Câncer de Bexiga': 'Bladder Cancer', 'Câncer de Ovário': 'Ovarian Cancer', 'Câncer de Endométrio': 'Endometrial Cancer',
    'Câncer de Esôfago': 'Esophageal Cancer', 'Câncer de Cabeça e Pescoço': 'Head and Neck Cancer', 'Câncer de Tireoide': 'Thyroid Cancer',
    'Câncer de Testículo': 'Testicular Cancer', 'Câncer de Cérebro': 'Brain Cancer', 'Linfoma de Hodgkin': 'Hodgkin Lymphoma',
    'Linfoma Não-Hodgkin': 'Non-Hodgkin Lymphoma', 'Leucemia': 'Leukemia', 'Mieloma Múltiplo': 'Multiple Myeloma',
    'Câncer de Vias Biliares': 'Bile Duct Cancer', 'Câncer de Glândula Salivar': 'Salivary Gland Cancer', 'Câncer de Vulva': 'Vulvar Cancer',
    'Câncer de Vagina': 'Vaginal Cancer', 'Câncer de Pleura': 'Pleural Cancer', 'Câncer de Peritônio': 'Peritoneal Cancer',
    'Câncer de Nasofaringe': 'Nasopharyngeal Cancer', 'Câncer de Hipofaringe': 'Hypopharyngeal Cancer', 'Câncer de Laringe': 'Laryngeal Cancer',
    'Câncer de Pequenas Células do Pulmão': 'Small Cell Lung Cancer', 'Câncer de Células Renais': 'Renal Cell Cancer',
    'Sarcoma de Tecidos Moles': 'Soft Tissue Sarcoma', 'Osteossarcoma': 'Osteosarcoma', 'Sarcoma de Ewing': 'Ewing Sarcoma',
    'Mesotelioma': 'Mesothelioma', 'Câncer de Adrenal': 'Adrenal Cancer', 'Câncer de Medula Espinhal': 'Spinal Cord Cancer',
    'Câncer de Útero': 'Uterine Cancer', 'Câncer de Colo do Útero': 'Cervical Cancer', 'Câncer Peniano': 'Penile Cancer',
    'Câncer de Paratireoide': 'Parathyroid Cancer', 'Câncer de Vesícula Biliar': 'Gallbladder Cancer',
    'Câncer de Glândula Pituitária': 'Pituitary Gland Cancer', 'Plasmocitoma': 'Plasmacytoma', 'Câncer de Nervo Periférico': 'Peripheral Nerve Cancer',
    'Melanoma': 'Melanoma', 'Câncer de Pele Não-Melanoma': 'Non-Melanoma Skin Cancer', 'Câncer de Apêndice': 'Appendix Cancer',
    'Tumor de Saco Vitelino': 'Yolk Sac Tumor', 'Câncer de Cordão Espinhal': 'Spinal Cord Tumor', 'Câncer de Intestino Delgado': 'Small Intestine Cancer',
    'Câncer de Ampola de Vater': 'Ampulla of Vater Cancer', 'Câncer de Cavidade Oral': 'Oral Cavity Cancer',
    'Câncer de Seio Paranasal': 'Paranasal Sinus Cancer', 'Câncer de Glândula Lacrimal': 'Lacrimal Gland Cancer',
    'Adenocarcinoma de Pulmão': 'Adenocarcinoma of the Lung', 'Carcinoma de Células Escamosas de Pulmão': 'Squamous Cell Carcinoma of the Lung',
    'Carcinoma de Células Pequenas de Pulmão': 'Small Cell Carcinoma of the Lung', 'Glioblastoma Multiforme': 'Glioblastoma Multiforme',
    'Astrocitoma': 'Astrocytoma', 'Oligodendroglioma': 'Oligodendroglioma', 'Ependimoma': 'Ependymoma',
    'Carcinóide Pulmonar': 'Pulmonary Carcinoid', 'Carcinóide Gastrointestinal': 'Gastrointestinal Carcinoid',
    'Tumor Estromal Gastrointestinal': 'Gastrointestinal Stromal Tumor', 'Linfoma de Burkitt': 'Burkitt Lymphoma',
    'Linfoma Folicular': 'Follicular Lymphoma', 'Leucemia Linfoblástica Aguda': 'Acute Lymphoblastic Leukemia',
    'Leucemia Mieloide Aguda': 'Acute Myeloid Leukemia', 'Leucemia Mieloide Crônica': 'Chronic Myeloid Leukemia',
    'Leucemia Linfocítica Crônica': 'Chronic Lymphocytic Leukemia', 'Carcinoma Ductal Invasivo': 'Invasive Ductal Carcinoma',
    'Carcinoma Lobular Invasivo': 'Invasive Lobular Carcinoma', 'Carcinoma Hepatocelular': 'Hepatocellular Carcinoma',
    'Colangiocarcinoma': 'Cholangiocarcinoma', 'Tumor de Células de Leydig': 'Leydig Cell Tumor',
    'Tumor de Células de Sertoli': 'Sertoli Cell Tumor', 'Tumor de Células Germinativas Não-Seminomatoso': 'Non-Seminomatous Germ Cell Tumor',
    'Rabdomiossarcoma': 'Rhabdomyosarcoma', 'Sarcoma de Kaposi': 'Kaposi Sarcoma', 'Lipossarcoma': 'Liposarcoma',
    'Leiomiossarcoma': 'Leiomyosarcoma', 'Tumor de Wilms': 'Wilms Tumor', 'Retinoblastoma': 'Retinoblastoma',
    'Neuroblastoma': 'Neuroblastoma', 'Meduloblastoma': 'Medulloblastoma', 'Carcinoma Adrenocortical': 'Adrenocortical Carcinoma',
    'Carcinoma Papilífero da Tireoide': 'Papillary Thyroid Carcinoma', 'Carcinoma Folicular da Tireoide': 'Follicular Thyroid Carcinoma',
    'Carcinoma Medular da Tireoide': 'Medullary Thyroid Carcinoma', 'Carcinoma Anaplásico da Tireoide': 'Anaplastic Thyroid Carcinoma',
    'Câncer de Células de Merkel': 'Merkel Cell Cancer', 'Carcinoma Basocelular': 'Basal Cell Carcinoma',
    'Carcinoma Espinocelular': 'Squamous Cell Carcinoma', 'Cistadenoma Seroso do Ovário': 'Serous Cystadenoma of the Ovary',
    'Cistadenocarcinoma Mucinoso do Ovário': 'Mucinous Cystadenocarcinoma of the Ovary', 'Tumor de Células da Granulosa do Ovário': 'Granulosa Cell Tumor of the Ovary',
    'Tumor de Brenner do Ovário': 'Brenner Tumor of the Ovary', 'Demência': 'Dementia', 'Hiperplasia Prostática Benigna': 'Benign Prostatic Hyperplasia',
    'Hipertensão Arterial': 'Hypertension', 'Diabetes Mellitus': 'Diabetes Mellitus', 'Doença de Alzheimer': 'Alzheimer Disease',
};

function traduzirDoenca(doenca) {
    const doencaNormalizada = doenca.trim().toLowerCase();
    const chaveEncontrada = Object.keys(traducoesDeCancer).find(key => key.toLowerCase() === doencaNormalizada);
    if (chaveEncontrada) {
      return traducoesDeCancer[chaveEncontrada];
    } else {
      return doenca;
    }
  }
  


export { TiposdeCancer, traduzirDoenca };
