import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person'; // Ícone para paciente
import ProfessionalIcon from '@mui/icons-material/LocalHospital'; // Ícone para profissional
import './UserTypeToggle.css'; // Importando o CSS para as animações

function UserTypeToggle({ userType, handleUserTypeClick }) {
  const [visible, setVisible] = useState(true);
  const isPatient = userType === 'Paciente';
  const Icon = isPatient ? PersonIcon : ProfessionalIcon;

  const toggleVisibility = () => {
    setVisible(false); // Inicia o fade-out
    setTimeout(() => {
      handleUserTypeClick(isPatient ? 'Profissional' : 'Paciente');
      setVisible(true); // Inicia o fade-in após 0.2 segundo
    }, 50);
  };

  return (
    <div className="container">
      <br />
      <br />
      <br />
      <Tooltip title={isPatient ? 'Mudar para Profissional' : 'Mudar para Paciente'}>
        <Button
          onClick={toggleVisibility}
          startIcon={<Icon />}
          variant="contained"
          color="secondary"
          className={`${visible ? 'fade-in' : 'fade-out'} margin-adjust`}
        >
          {isPatient ? 'Paciente' : 'Profissional'}
        </Button>
      </Tooltip>
    </div>
  );
}

export default UserTypeToggle;
